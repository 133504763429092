import {
  AsyncRequest,
  MultiListingDaoOutput,
  UseListingResponseType,
} from "@idsk/ui-core-framework";
import { QueryObserverOptions } from "@tanstack/query-core";
import { UnAuthJobBoardAxiosInstance as axios } from "@idsk/ui-core-framework";
import { Client } from "@idsk/components-props";

export interface ClientListResponse {
  total: number;
  records: Client[];
}

export class UsePostToMultiSearchClientsDao extends AsyncRequest<
  UseListingResponseType<ClientListResponse>
> {
  private readonly contentFrom: number;

  constructor(
    private readonly pageNumber: number,
    private readonly pageSize: number,
    private readonly queryParams?: object
  ) {
    super();
    this.contentFrom = (this.pageNumber - 1) * this.pageSize;
  }
  private getEncodeParams = (params: any) => {
    const queryParams = Object.keys(params).reduce(
      (acc, key) => {
        acc[key] = encodeURIComponent(params[key]);
        return acc;
      },
      {} as {
        [key: string]: string;
      }
    );
    return queryParams;
  };

  getConfig = (): QueryObserverOptions<any, Error> => {
    return {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    };
  };
  getAsyncFunction = async (): Promise<
    UseListingResponseType<ClientListResponse>
  > => {
    let url = `/v2/candidateApp/usePostToMultiSearch/clients`;
    let queryParams = this.getEncodeParams(this.queryParams!);

    const res = await axios.put(url, queryParams, {
      params: {
        from: encodeURIComponent(this.contentFrom.toString()),
        size: encodeURIComponent(this.pageSize.toString()),
        ...queryParams,
      },
    });
    return res.data;
  };

  getCacheKey = (): string[] => {
    return [
      "getClientsDataList",
      this.contentFrom.toString(),
      this.pageSize.toString(),
      this.pageNumber.toString(),
      JSON.stringify(this.queryParams),
    ];
  };
}
