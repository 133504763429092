import { createServer } from "miragejs";
import { ModelRegistry } from "./MirageModels";
import {
  CandidateBffEndPointConfig,
  getStageType,
} from "@idsk/ui-core-framework";
import {
  getAssessmentData,
  mockGetAssessmentSessionById,
  mockGetAssessmentSessionList,
  mockPatchAssessmentSessionById,
  mockRunCode,
} from "./AssessmentSession";
import { createQuestions } from "./AssessmentQuestion";
import { createAssessments } from "./Assessment";

export function makeServer() {
  const environment = getStageType() || "development";
  return createServer({
    environment,
    models: ModelRegistry,
    routes() {
      this.urlPrefix = `${CandidateBffEndPointConfig[environment]}/api`;
      this.timing = 1000;
      this.get(
        `/v1/d2s/assessmentsessions/clients/:clientId/assessmentSessions/`,
        mockGetAssessmentSessionById
      );

      this.patch(
        `/v1/d2s/assessmentsessions/clients/:clientId/assessmentSessions/:id`,
        mockPatchAssessmentSessionById
      );
      this.put(
        "/v1/clients/:clientId/assessments/:assessmentId/actions/RUN_CODE",
        mockRunCode
      );
      this.post(
        `/v1/d2s/questionandtests/clients/:clientId/codingQuestionTestRuns`,
        () => {
          console.log("called from mirage");

          return 200;
        }
      );
      this.get(
        `/v1/d2s/questionandtests/clients/:clientId/codingQuestionTestRuns`,
        () => {
          return 200;
        }
      );
      this.post(
        "/clients/:clientId/assessmentSessions",
        mockGetAssessmentSessionList
      );

      this.passthrough(
        (request) =>
          !(
            request.queryParams?.useMirage ??
            request.requestHeaders?.useMirage ??
            false
          )
      );
    },
    seeds(server) {
      for (let i = 0; i < 50; i++) {
        server.create("question", createQuestions());
        server.create("assessment", createAssessments());
        server.create("assessmentSession", getAssessmentData());
      }
    },
  });
}
