import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Col, Form, Row, Statistic, Typography } from "antd";
import { useContext, useState } from "react";
import {
  UserDetails,
  forgotPassword,
  resendOTP,
  signIn,
  verifyUserOtp,
} from "../cognito/cognitoUtils";
import { UserContext } from "@idsk/ui-core-framework";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Buffer } from "buffer";

export const VerificationOTP = () => {
  const form = Form.useFormInstance();
  const OTP: string = Form.useWatch("verificationOtp", form);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const purpose = searchParams.get("purpose");
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [resend, setResend] = useState<boolean>(false);
  const [countValue, setCountValue] = useState(Date.now() + 120 * 1000);
  const [error, setError] = useState<string>();

  const onConfirmOTP = (values: UserDetails) => {
    setLoading(true);
    const finalValues: UserDetails = {
      ...values,
      username:
        values.username ??
        Buffer.from(searchParams.get("username") ?? "", "base64").toString(),
    };
    purpose === "forgot_password"
      ? navigate(
          `/reset_password?${createSearchParams({
            username: values.username
              ? Buffer.from(values.username ?? "").toString("base64")
              : searchParams.get("username")!,
          })}`
        )
      : verifyUserOtp(finalValues, setUser!).catch((e) => {
          if (
            e.message ===
            "PostConfirmation invocation failed due to error Socket timeout while invoking Lambda function."
          ) {
            signIn(values, setUser!);
          }
          setError(e.message);
          setLoading(false);
        });
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{ position: "relative", top: "25%" }}
    >
      <Col span={13}>
        <Typography.Text
          style={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#0d3f4b",
            font: "Circular Std",
          }}
        >
          Enter Verifcation Code
        </Typography.Text>

        <p>We've sent the verification code to your email address.</p>
      </Col>
      {error && (
        <Col span={13}>
          <Alert type="error" message={error} />
        </Col>
      )}
      <Col
        span={13}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <TextInput name="verificationOtp" noStyle required />
        <Statistic.Countdown
          format="mm:ss"
          valueStyle={{ fontSize: "1em" }}
          value={countValue}
          onFinish={() => {
            setResend(true);
            setError(undefined);
          }}
        />
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: OTP?.length !== 6 ? "#f5f5f5" : "#F89621",
            color: OTP?.length !== 6 ? "grey" : "white",
            borderColor: OTP?.length !== 6 ? "#D9D9D9" : "#E88C20",
          }}
          disabled={OTP?.length !== 6}
          onClick={() => {
            onConfirmOTP(form.getFieldValue([]));
          }}
          size="large"
          loading={loading}
        >
          Verify Code
        </IdskButton>
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: resend ? "white" : "#f5f5f5",
            color: resend ? "black" : "grey",
            borderColor: resend ? "#E88C20" : "#D9D9D9",
          }}
          role="button"
          disabled={!resend}
          onClick={() => {
            const resendData = {
              ...form.getFieldValue([]),
              username:
                form.getFieldValue("username") ??
                Buffer.from(
                  searchParams.get("username") ?? "",
                  "base64"
                ).toString(),
            };
            purpose === "forgot_password"
              ? forgotPassword(resendData)
              : resendOTP(resendData);
            setCountValue(Date.now() + 120 * 1000);
            setResend(false);
          }}
          size="large"
        >
          Resend Code
        </IdskButton>
      </Col>
    </Row>
  );
};
