import { Auth } from "aws-amplify";
import { dev, preprod, redirectBaseUrls } from "./constants";
import jwt_decode from "jwt-decode";
import { amplifyConfig } from "./cognitoconfig";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types";

export interface UserDetails {
  fullname: string;
  username: string;
  password: string;
  newPassword?: string;
  confirmPassword?: string;
  verificationOtp?: string;
}

export function getHttp() {
  return `${window.location.origin}/`;
}

export function getRedirectUrls() {
  const baseOriginUrl = getHttp();

  const redirectUrlOnSignout =
    // @ts-ignore
    redirectBaseUrls[process.env.STAGE] + baseOriginUrl;

  switch (process.env.STAGE) {
    case dev: {
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_8jGrUzJtz",
        userPoolWebClientId: "26fd34l8aje5aidcsqrq81qds2",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
    case preprod: {
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_vBf8vsNYd",
        userPoolWebClientId: "3k926e6iqkba1lf67gdj83uen1",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
    default: {
      // By default, always point to prod
      return {
        domain: redirectUrlOnSignout.split("/")[2],
        userPoolId: "ap-south-1_2GLMnrTWF",
        userPoolWebClientId: "60h41369mpsh1a6rkj7mh5s91v",
        redirectSignIn: baseOriginUrl,
        redirectSignOut: baseOriginUrl,
      };
    }
  }
}

export function createUserContextFromCognito(user: any, webClientId: string) {
  if (user === null || user === "") {
    return {
      mail: "",
      clientId: "",
      idToken: "",
      isSignedIn: false,
    };
  }
  let key = `CognitoIdentityServiceProvider.${webClientId}.${user.username}.idToken`;
  const idToken = user.storage[key];
  if (idToken === undefined) {
    return { mail: "", clientId: "", idToken: "", isSignedIn: false };
  }
  let decodedToken: any = jwt_decode(idToken);
  const mail = decodedToken.email;
  const clientId = decodedToken["custom:idskClientId"];
  const id = decodedToken["custom:idskUserId"];
  return { idToken, clientId, mail, isSignedIn: true, id };
}

export const signUp = (values: UserDetails) => {
  return Auth.signUp({
    username: values.username,
    password: values.password,
  });
};

export const signIn = (
  values: UserDetails,
  setUser: React.Dispatch<React.SetStateAction<string | null>>
): Promise<void> => {
  return Auth.signIn(values.username, values.password);
};

export const verifyUserOtp = (
  values: UserDetails,
  setUser: React.Dispatch<React.SetStateAction<string | null>>
) => {
  return Auth.confirmSignUp(values.username, values.verificationOtp!).then(
    async () => {
      await signIn(values, setUser);
      Auth.currentAuthenticatedUser().then((currentUser) => {
        setUser!(currentUser);
        localStorage.setItem(
          "idToken",
          `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
        );
      });
    }
  );
};

export const onFederatedSignIn = (provider: string, custom: boolean) => {
  custom
    ? Auth.federatedSignIn({ customProvider: provider })
    : Auth.federatedSignIn({
        provider: provider as CognitoHostedUIIdentityProvider,
      });
};

export const forgotPassword = (values: UserDetails) => {
  return Auth.forgotPassword(values.username);
};

export const forgotPasswordSubmit = (values: UserDetails) => {
  return Auth.forgotPasswordSubmit(
    values.username,
    values.verificationOtp!,
    values.newPassword!
  );
};

export const resendOTP = (values: UserDetails) => {
  return Auth.resendSignUp(values.username);
};

export const validatePassword = (value: string) => {
  if (!/[a-z]/.test(value)) {
    return Promise.reject("Password must contain a lower case letter");
  } else if (!/[A-Z]/.test(value)) {
    return Promise.reject("Password must contain an upper case letter");
  } else if (!/\d/.test(value)) {
    return Promise.reject("Password must contain a number");
  } else if (value.length < 8) {
    return Promise.reject("Password must contain at least 8 characters");
  } else if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(value)) {
    return Promise.reject("Password must contain a special character");
  }
  return Promise.resolve();
};
