import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router";
import {
  UserContext,
  attachHandlers,
  getStageType,
} from "@idsk/ui-core-framework";
import { RootContainer } from "./container/RootContainer";
import { amplifyConfig } from "./cognito/cognitoconfig";
import { createUserContextFromCognito } from "./cognito/cognitoUtils";
import AWS from "aws-sdk";

import { LoginContainer } from "./container/LoginContainer";
import {
  ThemeWrapperDataFetcher,
  ThemeWrapperProps,
} from "./container/ThemeWrapper.df";
import { region, unAuthCognitoIdentityPoolIds } from "./cognito/constants";
import { Amplify, Auth, Hub } from "aws-amplify";
import { getListener } from "./cognito/cognitoEventHandlers";
import { ThemeWrapperLayout } from "./container/ThemeWrapper.layout";

Amplify.configure(amplifyConfig);
const environment = getStageType();
AWS.config.region = region;
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: unAuthCognitoIdentityPoolIds[environment],
});
const ThemeWrapperPage = attachHandlers<
  ThemeWrapperProps,
  { children: JSX.Element }
>("ThemeWrapperPage")(ThemeWrapperDataFetcher)(ThemeWrapperLayout);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultUser = process.env.SKIP_LOGIN ? "" : null;
  const { isSignedIn } = useContext(UserContext);
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const checkUserLoggedIn = () => {
      console.log("Check Logged In Triggered in Assessment");
      if (!isSignedIn) {
        if (
          location.pathname.includes("assessment-administration") ||
          location.pathname.includes("assessment-sessions")
        ) {
          localStorage.setItem(
            "redirectPath",
            location.pathname + location.search
          );
          navigate("/login");
        }
      }
    };
    checkUserLoggedIn();
  }, []);

  const addUnAuthCredentialsToSessionStorage = () => {
    credentials.get((err: AWS.AWSError | undefined) => {
      if (err) {
        console.error("error getting credentials = ", err.message);
        return;
      }
      localStorage.setItem("accessKey", credentials.accessKeyId);
      localStorage.setItem("secretKey", credentials.secretAccessKey);
      localStorage.setItem("sessionToken", credentials.sessionToken);
    });
  };

  useEffect(() => {
    const listener = getListener(setUser);
    Hub.listen("auth", listener);
    addUnAuthCredentialsToSessionStorage();
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        localStorage.setItem(
          "idToken",
          `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
        );
      })
      .catch((err) => {
        console.log(
          "Error occurred, while trying to get authenticated user. Probably the user is not logged in",
          err
        );
      });
  }, []);

  let values = createUserContextFromCognito(
    user,
    amplifyConfig.Auth.userPoolWebClientId
  );

  return (
    <UserContext.Provider value={{ ...values, setUser }}>
      <ThemeWrapperPage
        children={!values.isSignedIn ? <LoginContainer /> : <RootContainer />}
      />
    </UserContext.Provider>
  );
}

export default App;
