import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Button, Col, Form, Modal, Row, Typography } from "antd";
import { useContext, useState } from "react";
import {
  UserDetails,
  onFederatedSignIn,
  signUp,
  validatePassword,
  verifyUserOtp,
} from "../cognito/cognitoUtils";
import { useNavigate } from "react-router";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineLinkedin } from "react-icons/ai";
import { UserContext } from "@idsk/ui-core-framework";
import { createSearchParams } from "react-router-dom";
import { Buffer } from "buffer";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

export const SignUpPage = () => {
  const form = Form.useFormInstance<UserDetails>();
  const [error, setError] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSignUp = async (values: UserDetails) => {
    form.validateFields();
    if (values.username && values.password) {
      signUp(values)
        .then(() => {
          navigate(
            `/confirm?${createSearchParams({
              username: Buffer.from(values.username).toString("base64"),
              purpose: "signup",
            })}`
          );
        })
        .catch((e) => {
          setError(e.message);
        });
    }
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{ position: "relative", top: "15%" }}
    >
      <Col span={13}>
        <Typography.Text
          style={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#0d3f4b",
            font: "Circular Std",
          }}
        >
          Create Account
        </Typography.Text>

        <p>Sign up with Stint Code to create an account</p>

        <Button
          style={{
            width: "100%",
            borderColor: "#E88C20",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() =>
            onFederatedSignIn(CognitoHostedUIIdentityProvider.Google, false)
          }
          size="large"
          icon={<FcGoogle size={24} style={{ marginRight: "1em" }} />}
        >
          Sign up with Google
        </Button>
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            borderColor: "#2094E8",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          role="button"
          onClick={() => onFederatedSignIn("LinkedIn", true)}
          size="large"
        >
          <AiOutlineLinkedin
            size={24}
            color="#5AB0F7"
            style={{ marginRight: "1em" }}
          />
          Sign up with LinkedIn
        </IdskButton>
      </Col>
      <Col span={7} offset={7}>
        <Typography.Text>- OR -</Typography.Text>
      </Col>
      {error && (
        <Col span={13}>
          <Alert type="error" message={error} />
        </Col>
      )}

      <Col span={13}>
        <TextInput
          name="name"
          required
          label="Full Name"
          placeholder="Full Name"
        />
      </Col>
      <Col span={13}>
        <TextInput
          name="username"
          required
          label="Email Address"
          placeholder="Email"
        />
      </Col>
      <Col span={13}>
        <TextInput
          name="password"
          rules={[
            {
              required: true,
              message: "Email Address is required",
            },
            () => ({
              validator(_, password) {
                return validatePassword(password);
              },
            }),
          ]}
          label="Password"
          type={showPassword ? "text" : "password"}
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
            )
          }
          placeholder="Password"
        />
      </Col>

      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: "#F89621",
            color: "white",
          }}
          role="button"
          onClick={() => {
            onSignUp(form.getFieldValue([]));
          }}
          size="large"
        >
          Sign up
        </IdskButton>
      </Col>
      <Col offset={7} span={12} style={{ marginTop: "1%" }}>
        <Typography.Text>
          Already have an account?{" "}
          <Typography.Link underline onClick={() => navigate("/login")}>
            Sign in
          </Typography.Link>{" "}
        </Typography.Text>
      </Col>
    </Row>
  );
};
