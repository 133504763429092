import { getRedirectUrls } from "./cognitoUtils";
import { mandatorySignIn, region, responseType, scope } from "./constants";
import { config } from "@idsk/code-collab-app";

const {
  redirectSignIn,
  redirectSignOut,
  domain,
  userPoolId,
  userPoolWebClientId,
} = getRedirectUrls();

export const amplifyConfig = {
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId,
    mandatorySignIn,
    oauth: {
      domain,
      scope,
      redirectSignIn,
      redirectSignOut,
      responseType,
    },
  },
  ...config,
};

// @ts-ignore
window.amplifyConfig = amplifyConfig;
