import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Col, Form, Row, Typography } from "antd";
import React, { useState } from "react";
import { UserDetails, forgotPassword } from "../cognito/cognitoUtils";
import { useNavigate } from "react-router";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Buffer } from "buffer";

export const ForgotPassword = () => {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const onSendOTP = (values: UserDetails) => {
    forgotPassword(values).then(() => {
      navigate(
        `/confirm?${createSearchParams({
          username: Buffer.from(values.username).toString("base64"),
          purpose: "forgot_password",
        })}`
      );
    });
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{ position: "relative", top: "25%" }}
    >
      <Col span={13}>
        <Typography.Text
          style={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#0d3f4b",
            font: "Circular Std",
          }}
        >
          Reset Password
        </Typography.Text>

        <p>Enter your Email to Reset Password.</p>
      </Col>
      {searchParams.get("purpose") === "reset_required" && (
        <Col span={13}>
          <Alert
            type="error"
            message={
              "Password reset required for user due to security reasons."
            }
          />
        </Col>
      )}
      {error && (
        <Col span={13}>
          <Alert type="error" message={error} />
        </Col>
      )}
      <Col span={13}>
        <TextInput
          name="username"
          required
          label="Email Address"
          placeholder="Email"
        />
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: "#F89621",
            color: "white",
            borderColor: "#E88C20",
          }}
          onClick={() => {
            onSendOTP(form.getFieldValue([]));
          }}
          size="large"
        >
          Proceed
        </IdskButton>
      </Col>
    </Row>
  );
};
