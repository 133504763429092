import { Logger } from "aws-amplify";
const logger = new Logger("idesk-web-app");

export function getListener(setUser: any) {
  return (dataFromAuthEvent: any) => {
    const { event, data } = dataFromAuthEvent.payload;
    switch (event) {
      case "signIn":
      case "cognitoHostedUI":
        logger.info("user signed in");
        setUser(data);
        break;
      case "signOut":
        logger.info("user signed out");
        setUser(null);
        break;
      case "customOAuthState":
        logger.error("customOAuthState");
        break;
      case "signIn_failure":
        logger.error("user sign in failed");
        break;
      case "tokenRefresh":
        logger.info("token refresh succeeded");
        break;
      case "tokenRefresh_failure":
        logger.error("token refresh failed");
        break;
      case "autoSignIn":
        logger.info("Auto Sign In after Sign Up succeeded");
        break;
      case "autoSignIn_failure":
        logger.error("Auto Sign In after Sign Up failed");
        break;
      case "configured":
        logger.info("the Auth module is configured");
        break;
    }
  };
}
