import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { IdskButton, TextInput } from "@idsk/components-ui";
import { Alert, Button, Col, Form, Row, Typography } from "antd";
import {
  UserDetails,
  onFederatedSignIn,
  signIn,
  validatePassword,
} from "../cognito/cognitoUtils";
import { useContext, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { UserContext } from "@idsk/ui-core-framework";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { amplifyConfig } from "../cognito/cognitoconfig";
import { AiOutlineLinkedin } from "react-icons/ai";

export const SignInPage = () => {
  const navigate = useNavigate();
  const form = Form.useFormInstance();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);

  const onSignIn = async (values: UserDetails) => {
    setError(undefined);
    setLoading(true);
    form.validateFields();
    if (values.password && values.username) {
      signIn(values, setUser!)
        .catch((error) => {
          if (error.message === "Password reset required for the user") {
            navigate("/forgot_password?purpose=reset_required");
          }
          setError(error.message);
        })
        .then((data: any) => {
          if (
            data.challengeName === "NEW_PASSWORD_REQUIRED" ||
            data.challengeName === "PASSWORD_VERIFIER"
          ) {
            navigate("/reset_password?purpose=new_password");
          } else {
            Auth.currentAuthenticatedUser().then((currentUser) => {
              setUser!(currentUser);
              localStorage.setItem(
                "idToken",
                `CognitoIdentityServiceProvider.${amplifyConfig.Auth.userPoolWebClientId}.${currentUser.username}.idToken`
              );
            });
          }
        });
    }
    setLoading(false);
  };

  return (
    <Row
      align={"middle"}
      justify={"center"}
      gutter={[12, 12]}
      style={{ position: "relative", top: "15%" }}
    >
      <Col span={13}>
        <Typography.Text
          style={{
            fontWeight: "500",
            fontSize: "24px",
            color: "#0d3f4b",
            font: "Circular Std",
          }}
        >
          Sign in
        </Typography.Text>

        <p>Sign in to access Stint Code</p>

        <Button
          style={{
            width: "100%",
            borderColor: "#E88C20",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() =>
            onFederatedSignIn(CognitoHostedUIIdentityProvider.Google, false)
          }
          icon={<FcGoogle size={24} style={{ marginRight: "1em" }} />}
          size="large"
        >
          Sign in with Google
        </Button>
      </Col>
      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            borderColor: "#F89621",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          role="button"
          onClick={() => onFederatedSignIn("LinkedIn", true)}
          size="large"
        >
          <AiOutlineLinkedin
            size={24}
            color="#5AB0F7"
            style={{ marginRight: "1em" }}
          />
          Sign in with LinkedIn
        </IdskButton>
      </Col>
      <Col span={7} offset={7}>
        <Typography.Text>- OR -</Typography.Text>
      </Col>
      {error && (
        <Col span={13}>
          <Alert type="error" message={error} />
        </Col>
      )}
      <Col span={13}>
        <TextInput
          name="username"
          required
          label="Email Address"
          placeholder="Email"
        />
      </Col>
      <Col span={13}>
        <TextInput
          name="password"
          rules={[
            {
              required: true,
              message: "Email Address is required",
            },
            () => ({
              validator(_, password) {
                return validatePassword(password);
              },
            }),
          ]}
          label="Password"
          type={showPassword ? "text" : "password"}
          placeholder="Password"
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
            )
          }
          formStyle={{ marginBottom: 0 }}
        />

        <Button
          type="link"
          style={{ color: "#E88C20", padding: 0 }}
          onClick={() => {
            navigate(`/forgot_password`);
          }}
        >
          Forgot password?
        </Button>
      </Col>

      <Col span={13}>
        <IdskButton
          style={{
            width: "100%",
            background: "#F89621",
            color: "white",
          }}
          role="button"
          onClick={() => {
            onSignIn(form.getFieldValue([]));
          }}
          size="large"
          loading={loading}
        >
          Sign in
        </IdskButton>
      </Col>
      <Col offset={7} span={12} style={{ marginTop: "1%" }}>
        <Typography.Text>
          Don't have an account?{" "}
          <Typography.Link underline onClick={() => navigate("/signup")}>
            Sign up
          </Typography.Link>{" "}
        </Typography.Text>
      </Col>
    </Row>
  );
};
